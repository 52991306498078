import React, { useEffect, useState, useContext } from "react";
import styles from "./Projects.module.css";
import modules from "./Plan.module.css";
import DecoratedTitle from "../components/DecoratedTitle";
import Card from "../components/Card";
import getData from "./../api/head";
import UserContext from "../data/UserContext";

const Projects = () => {
  const [data, setData] = useState("");
  const {BASEURL, upload, changeShowMakett, changeShowMakettValue} = useContext(UserContext);

  useEffect(() => {
    // getData("projects.php?name=projects");

    fetch(`${BASEURL}projects.php?name=projects`)
    .then((data) => data.json())
    .then((data) => {
      setData(data);
    })
  }, [upload]);

  return (
    <div className={styles.project} id="projects">
      <div className={modules.titlesContainer}>
        <DecoratedTitle
          className={`${modules.title} ${styles.titleSize}`}
          backTitle={`${modules.backTitle} ${styles.titleSize}`}
        >
          Makettek
        </DecoratedTitle>
      </div>

      <div className={styles.Cards}>
        {data !== ""
          ? data.data.map((project, index) =>
              <Card
                title={project.title}
                key={index}
                icon={project.icon}
                id={project.postID}
                onClick={() => {changeShowMakett(true); changeShowMakettValue(project.postID)}}
              />
            )
          : ""}
      </div>
    </div>
  );
};

export default Projects;
