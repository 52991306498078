import React, {useContext, useState} from 'react'
import styles from "./Edit.module.css";
import UserContext from "../data/UserContext";
import axios from "axios";
import Back from "../icons/back.svg";
import Tick from "../icons/tick.svg";

const PicUpload = () => {
    const {
        BASEURL,
        changeUpload,
        errorHandler,
        newStuff,
        changeStuffContext,
        showMakett
      } = useContext(UserContext);
      const [newProject, setNewProject] = useState({
        media: "",
        mediaValid: false
      });

      const MediaHandler = e => {
        console.log(URL.createObjectURL(e.target.files[0]));
        for (let i = 0; i < e.target.files.length; i++) {
          if (e.target.files[i].size < 25000000) {
            setNewProject(prev => ({
              ...prev,
              media: e.target.files,
              mediaValid: false
            }));
          } else {
            errorHandler(
              "A feltölteni kívánt fájl mérete nem lehet nagyobb 25 Mb-nál!",
              "fail"
            );
            setNewProject(prev => ({
              ...prev,
              media: e.target.files,
              mediaValid: true
            }));
          }
        }
      };

      const sendHandler = () => {
        const url = `${BASEURL}newPictures.php`;
        const formData = new FormData();
    
        for (let i = 0; i < newProject.media.length; i++) {
          formData.append("file[]", newProject.media[i]);
        }
    
        axios.post(url, formData).then(response => {
          if (response.data.error === "none") {
            errorHandler("Új makett létrehozva!", "success");
            changeUpload();
          } else if (response.data.error === "fileSize") {
            errorHandler(
              "A feltölteni kívánt fájl mérete nem lehet nagyobb 1 GB-nál!",
              "fail"
            );
            setNewProject(prev => ({
              ...prev,
              mediaValid: true
            }));
          } else if (response.data.error === "notAllowed") {
            errorHandler("Nem megengedett fájltípus!", "fail");
            setNewProject(prev => ({
              ...prev,
              mediaValid: true
            }));
          } else if (response.data.error === "stmtFail") {
            errorHandler(
              "Hoppá! Valamilyen hiba történt. Próbáld meg újra vagy próbálkozz később.",
              "fail"
            );
          }
    
        });
      };
  return (
    <div>
<div className={styles.controlBtns}>
        <button
          className={styles.controls}
          onClick={() => changeStuffContext(0)}
        >
          <img src={Back} />
        </button>
        <button className={styles.controls} onClick={sendHandler}>
          <img src={Tick} />
        </button>
      </div>
        <input className={`${newProject.mediaValid ? styles.invalid : styles.input}`}
          type="file"
          name="media"
          accept=".jpg, .jpeg, .png, .svg, .mp4"
          onChange={MediaHandler}
          multiple
          capture/>
    </div>
  )
}

export default PicUpload