import React from "react";

const BigScreen = () => {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 1H2C1.44772 1 1 1.44772 1 2V8"
        stroke-linecap="round"
      />
      <path
        d="M1 13L1 19C1 19.5523 1.44772 20 2 20H8"
        stroke-linecap="round"
      />
      <path
        d="M14 20H20C20.5523 20 21 19.5523 21 19V13"
        stroke-linecap="round"
      />
      <path
        d="M21 8V2C21 1.44772 20.5523 1 20 1L14 1"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default BigScreen;
