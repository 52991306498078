import "./App.css";
import Home from "./pages/Home";
import PictureShower from "./pages/PictureShower";
import { useContext } from "react";
import PictureContext from "./data/PictureContext";
import UserContext from "./data/UserContext";
import Navigation from "./sections/Navigation";
import Login from "./pages/Login";
import Makett from "./pages/Makett";
import Error from "./components/Error";
import Edit from "./components/Edit";
function App() {
  const { showPictures } = useContext(PictureContext);

  const {
    showUserLogin,
    showMakett,
    menuShow,
    ErrorShow,
    isLoggedin
  } = useContext(UserContext);
  return (
    <div
      className="App"
      style={{
        height: `${menuShow ? "100vh" : "auto"}`,
        overflowY: `${menuShow ? "hidden" : "auto"}`
      }}
    >
      <Navigation />
      {ErrorShow > 0 ? <Error /> : ""}
      {isLoggedin && sessionStorage.getItem("whoami") === "leader"
        ? <Edit />
        : ""}
      {showMakett.show
        ? <Makett project={showMakett.value} />
        : showUserLogin
          ? <Login />
          : !showPictures ? <Home /> : <PictureShower />}
    </div>
  );
}

export default App;
