import { createContext, useState, useEffect } from "react";

const UserContext = createContext();

export function UserCon({ children }) {
  const [showUserLogin, setShowUserLogin] = useState(false);
  const [showMakett, setShowMakett] = useState({
    value: null,
    show: false
  });
  const [menuShow, setMenuShow] = useState(false);
  const [ErrorShow, setErrorShow] = useState(0);
  const [ErrorDesc, setErrorDesc] = useState("Sikeres csatlakozás!");
  const [ErrorType, setErrorType] = useState("");
  const [isLoggedin, setLoggedin] = useState(false);
  const [upload, setUpload] = useState(false);
  const [newStuff, setNewStuff] = useState(0);

  useEffect(
    () => {
      if (sessionStorage.getItem("whoami") !== null) {
        setLoggedin(true);
      } else {
        setLoggedin(false);
      }
    },
    [showUserLogin]
  );

  // const BASEURL = "http://192.168.0.26/";
  const BASEURL = "https://gyulaigergely.hu/server/";
  const changeMenuShow = () => {
    setMenuShow(!menuShow);
  };

  const changeStuffContext = (val) =>{
    setNewStuff(val);
  }

  const changeUpload = () => {
    setUpload(!upload);
  }

  const changeShowUserLoginContext = () => {
    setShowUserLogin(!showUserLogin);
  };

  const changeShowMakett = val => {
    setShowMakett(prev => ({
      ...prev,
      show: val
    }));
  };

  const changeShowMakettValue = val => {
    setShowMakett(prev => ({
      ...prev,
      value: val
    }));
  };

  const ShowError = val => {
    setErrorShow(val);
  };

  const errorHandler = (desc, type) => {
    setErrorDesc(desc);
    setErrorType(type);
    ShowError(1);
    setTimeout(() => {
      setErrorShow(0);
    }, 7000);
  };

  const loginControl = () => {
    if (sessionStorage.getItem("whoami") !== null) {
      setLoggedin(true);
    } else {
      setLoggedin(false);
    }
  };

  return (
    <UserContext.Provider
      value={{
        showUserLogin,
        changeShowUserLoginContext,
        showMakett,
        changeShowMakett,
        BASEURL,
        changeShowMakettValue,
        changeMenuShow,
        menuShow,
        errorHandler,
        ShowError,
        ErrorShow,
        ErrorType,
        ErrorDesc,
        loginControl,
        isLoggedin,
        changeUpload,
        upload,
        changeStuffContext,
        newStuff
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export default UserContext;
