import React, { useContext } from "react";
import Alert from "../icons/alert.svg";
import Success from "../icons/success.svg";
import "./Error.css";
import UserContext from "../data/UserContext";

const Error = () => {
  const { ShowError, ErrorType, ErrorDesc } = useContext(UserContext);

  const color = ErrorType === "fail" ? "fail" : "success";

  return (
    <>
      <button onClick={() => ShowError(0)} id="ErrorButton">
        <div className={`error ${color}`}>
          <img src={ErrorType === "fail" ? Alert : Success} alt="icon" />
          <p>{ErrorDesc}</p>
        </div>
      </button>
    </>
  );
};

export default Error;
