import React from "react";

const ArrowBack = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7988 20.799L6.70703 12.7072C6.3165 12.3167 6.3165 11.6835 6.70703 11.293L15 3"
        stroke-linecap="round"
        strokeWidth="3"
      />
    </svg>
  );
};

export default ArrowBack;
