import React, { useState, useContext } from "react";
import EditSvg from "../icons/edit.svg";
import Back from "../icons/back.svg";
import Tick from "../icons/tick.svg";
import styles from "./Edit.module.css";
import Input from "./Input";
import UserContext from "../data/UserContext";
import axios from "axios";
import NewPost from './NewPost';
import PicUpload from './PicUpload';

const Edit = () => {
  const { BASEURL, changeUpload, errorHandler, newStuff, changeStuffContext, showMakett } = useContext(UserContext);
  const [showMenu, setShowMenu] = useState(false);
  const [newProject, setNewProject] = useState({
    title: "",
    media: "",
    mediaName: "",
    mediaValid: false
  });

  
  const MediaHandler = e => {
    if (e.target.files[0].size <= 25000000) {
      setNewProject(prev => ({
        ...prev,
        media: e.target.files[0],
        mediaName: e.target.value,
        mediaValid: false
      }));
    } else {
      errorHandler(
        "A feltölteni kívánt fájl mérete nem lehet nagyobb 25 MB-nál!",
        "fail"
      );
      setNewProject(prev => ({
        ...prev,
        media: e.target.files[0],
        mediaName: e.target.value,
        mediaValid: true
      }));
    }
  };


  const NewProject = e => {
    setNewProject(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const sendHandler = () => {
    const url = `${BASEURL}newProject.php`;
    const formData = new FormData();
    formData.append("title", newProject.title);
    formData.append("file", newProject.media);

    axios.post(url, formData).then(response => {
      if (response.data.error === "none") {
        errorHandler("Új makett létrehozva!", "success");
        changeUpload();
      } else if (response.data.error === "fileSize") {
        errorHandler(
          "A feltölteni kívánt fájl mérete nem lehet nagyobb 25 MB-nál!",
          "fail"
        );
        setNewProject(prev => ({
          ...prev,
          mediaValid: true
        }));
      } else if (response.data.error === "notAllowed") {
        errorHandler("Nem megengedett fájltípus!", "fail");
        setNewProject(prev => ({
          ...prev,
          mediaValid: true
        }));
      } else if (response.data.error === "stmtFail") {
        errorHandler(
          "Hoppá! Valamilyen hiba történt. Próbáld meg újra vagy próbálkozz később.",
          "fail"
        );
      }
    });
  };

  return (
    <div>
      {newStuff === 2
        ? <div className={styles.newStuff}>
            <div className={styles.controlBtns}>
              <button
                className={styles.controls}
                onClick={() => changeStuffContext(0)}
              >
                <img src={Back} />
              </button>
              <button className={styles.controls} onClick={sendHandler}>
                <img src={Tick} />
              </button>
            </div>
            <div className={styles.form}>
              <Input
                type="text"
                name="title"
                placeholder="Cím"
                value={newProject.title}
                onChange={NewProject}
              />
              <input
               className={`${newProject.mediaValid ? styles.invalid : styles.input}`}
                type="file"
                name="media"
                accept=".jpg, .jpeg, .png, .svg"
                onChange={MediaHandler}
              />
            </div>
          </div>
        : 
        newStuff === 4 ?
        <div className={styles.newStuff}>

        <NewPost />
        </div>
        :
        newStuff === 3 ?
        <div className={styles.newStuff}>
          <PicUpload />
        </div>
        : 
        showMenu ?
        <div className={styles.editMenu}>
            {showMakett.show ? 
            <>
              <button onClick={() => changeStuffContext(1)}>Szerkesztés</button>
              <button onClick={() => changeStuffContext(4)}>Új poszt</button>
              </>
              : <>
              <button onClick={() => changeStuffContext(1)}>Szerkesztés</button>
              <button onClick={() => changeStuffContext(3)}>Új kép</button>
              <button onClick={() => changeStuffContext(2)}>Új makett</button> </>
            }
            </div> : ""}

      <button className={styles.editBtn} onClick={() => {
        changeStuffContext(0);
        setShowMenu(!showMenu)
      }}>
        <img src={EditSvg} />
      </button>
    </div>
  );
};

export default Edit;
