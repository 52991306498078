import styles from "./Pictures.module.css";
import projectsStyles from "./Projects.module.css";
import modules from "./Plan.module.css";
import DecoratedTitle from "../components/DecoratedTitle";
import ImgHolder from "../components/ImgHolder";
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import UserContext from "../data/UserContext";
import PictureContext from "../data/PictureContext";

const Pictures = () => {
  const {BASEURL, upload} = useContext(UserContext);
  const {showPicturesContext, updatePictureContext} = useContext(PictureContext);
  const imgBox = useRef();
  const [imgBoxScroll, setimgBoxScroll] = useState(null)
  const [images, setImages] = useState([]);
  const [images2, setImages2] = useState([]);

  useEffect(() => {
    
    imgBox.current.addEventListener('scroll', handleScroll)

    fetch(`${BASEURL}picture.php`)
          .then(data => data.json())
          .then(data => {
            let img = [];
            let img2 = [];

            for(let i = 0; i < data.sum; i++){
              if(i % 2 === 0){
                img.push({
                  "id": data.data[i].ImageID,
                  "index": i
                })
              } else {
                img2.push({
                  "id": data.data[i].ImageID,
                  "index": i
                });
              }
            }

            setImages(img);
            setImages2(img2);

          });
  }, [upload])
  
  const handleScroll = () => {
    setimgBoxScroll(imgBox.current.scrollLeft)
  }

  return (
    <>
      <div className={styles.container} id="pictures">
        <div className={modules.titlesContainer}>
          <DecoratedTitle
          className={`${modules.title} ${projectsStyles.titleSize}`}
          backTitle={`${modules.backTitle} ${projectsStyles.titleSize}`}
          >
            Képek
          </DecoratedTitle>
        </div>
      </div>

      <div className={styles.imgBox} ref={imgBox}>
        <div className={styles.imgContainer}>
          {images.map((i, ind) => (
            <ImgHolder
            key={ind}
            elementNumber={i.index}
            delId={i.id}
            dynamicLoad={true}
            imgBoxScrollPos = {imgBoxScroll}
            onClick={() => {
              showPicturesContext(true);
              updatePictureContext(i.index);
            }}
            />
            ))}

        </div>
        <div className={styles.imgContainer}>
        {images2.map((i, ind) => (
            <ImgHolder
            key={ind}
            elementNumber={i.index}
            delId={i.id}
            dynamicLoad={true}
            imgBoxScrollPos = {imgBoxScroll}
            onClick={() => {
              showPicturesContext(true);
              updatePictureContext(i.index);
            }}
            
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Pictures;
