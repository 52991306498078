import React, {useState, useEffect} from "react";
import styles from "./Input.module.css";

const Input = (props) => {
    const [valid, setValid] = useState(false);

    useEffect(() => {
        setValid(props.valid ?? false);
    }, [props]);

    // const invalidStyle = {
    //     borderColor: "#f00",
    //     boxShadow: "0 0 0 5px #ff000030"
    // }

  return (
    <div className={styles.holder}>
      <div className={styles.icon}>{props.icon}</div>
      <input
        type={props.type}
        name={props.name}
        className={`${valid ? styles.invalid : styles.input}`}
        placeholder={props.placeholder}
        onChange={props.onChange}
        value={props.value ?? ""}
      />
    </div>
  );
};

export default Input;
