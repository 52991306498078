import React, { useContext, useState, useEffect } from "react";
import PictureContext from "../data/PictureContext";
import UserContext from "../data/UserContext";
import styles from "./PictureShower.module.css";
import ImgHolder from "./../components/ImgHolder";
import ArrowBack from "./../icons/ArrowBack";
import ArrowForward from "./../icons/ArrowForward";
import Close from "./../icons/Close";
import BigScreen from "./../icons/BigScreen";
import SimpleImgHolder from "./../components/SimpleImgHolder";

const PictureShower = () => {
  const {
    showedPiture,
    showPicturesContext,
    updatePictureContext,
    showedPic
  } = useContext(PictureContext);
  const { BASEURL } = useContext(UserContext);

  const [bigStyle, setBigStyle] = useState({
    back: {
      top: `50%`,
      left: 0,
      width: `60%`,
      trasform: `translateY(-50%)`
    },
    front: {
      height: `60vh`
    }
  });
  const [changeScreen, setChangeScreen] = useState(false);
  const [images, setImages] = useState("");
  const [size, setSize] = useState(null);

  useEffect(() => {
    setBigStyle({
      back: {
        top: `0`,
        left: 0,
        width: `${window.innerWidth <= 992
          ? window.innerWidth <= 768 ? "100%" : "50%"
          : "60%"}`,
        height: `${window.innerWidth <= 992
          ? window.innerWidth <= 768 ? "55vh" : "50vh"
          : "60vh"}`,
        transform: `translateY(0)`
      },
      front: {
        height: `${window.innerWidth <= 992
          ? window.innerWidth <= 768
            ? "calc(55vh - 117px)"
            : "calc(50vh - 117px)"
          : "60vh"}`
      }
    });

    fetch(`${BASEURL}pictureAll.php?id=all`)
      .then(data => data.json())
      .then(data => {
        setImages(data);
      });

  }, []);

  const CloseImageViewerHandler = () => {
    showPicturesContext(false);
  };

  const ChosePictureHandler = val => {
    updatePictureContext(val);
  };

  const NextPictureHandler = () => {
    if (showedPic + 1 >= images.media.length) {
      updatePictureContext(0);
    } else {
      updatePictureContext(showedPic + 1);
    }
  };

  const PreviousPictureHandler = () => {
    if (showedPic - 1 < 0) {
      updatePictureContext(images.media.length - 1);
    } else {
      updatePictureContext(showedPic - 1);
    }
  };

  const BigScreenHandler = () => {
    setChangeScreen(!changeScreen);

    if (!changeScreen) {
      setBigStyle({
        back: {
          top: `0`,
          left: 0,
          width: `100%`,
          transform: `translateY(0)`
        },
        front: {
          height: `80vh`
        }
      });
    } else {
      setBigStyle({
        back: {
          top: `0`,
          left: 0,
          width: `${window.innerWidth <= 992
            ? window.innerWidth <= 768 ? "100%" : "50%"
            : "60%"}`,
          height: `${window.innerWidth <= 992
            ? window.innerWidth <= 768 ? "55vh" : "50vh"
            : "60vh"}`,
          transform: `translateY(0)`
        },
        front: {
          height: `${window.innerWidth <= 992
            ? window.innerWidth <= 768
              ? "calc(55vh - 117px)"
              : "calc(50vh - 117px)"
            : "60vh"}`
        }
      });
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.controls} style={bigStyle.back}>
        <button onClick={CloseImageViewerHandler} className={styles.button}>
          <Close />
        </button>
        <div className={styles.imgViwer} style={bigStyle.front}>
          {images !== ""
            ? images.media[showedPic].img.includes(".mp4")
              ? <video controls>
                  <source
                    src={`${BASEURL}img/${images?.media[showedPic]?.img}`}
                  />
                </video>
              : <img src={`${BASEURL}img/${images?.media[showedPic]?.img}`} />
            : ""}
        </div>
        <div className={styles.contolBtn}>
          <button
            className={styles.button}
            onClick={() => PreviousPictureHandler()}
          >
            <ArrowBack />
          </button>
          <button
            className={styles.button}
            onClick={() => NextPictureHandler()}
          >
            <ArrowForward />
          </button>
          <button
            className={`${styles.button} ${styles.flexEnd}`}
            onClick={() => BigScreenHandler()}
          >
            <BigScreen />
          </button>
        </div>
      </div>
   
      <div className={styles.imgLister}>
        {images !== ""
          ? images.media.map(
              (image, index) =>
                !image.img.includes("iframe")
                  ? <SimpleImgHolder
                      img={image.img}
                      key={index}
                      onClick={() => ChosePictureHandler(index)}
                    />
                  : ""
            )
          : ""}
      </div>
    </div>
  );
};

export default PictureShower;
