import React, { useContext, useEffect, useState } from "react";

import Header from "../sections/Header";
import Plan from "../sections/Plan";
import Projects from "../sections/Projects";
import Pictures from "../sections/Pictures";
import UserContext from "../data/UserContext";

const Home = () => {
  const {BASEURL, newStuff} = useContext(UserContext);
  const [data, setData] = useState("");

  useEffect(() => {
    fetch(`${BASEURL}api.php?name=head`)
    .then((data) => data.json())
    .then((data) => setData(data))
  }, [newStuff]);

  return (
    <div className="home">
      <Header
        title={data?.data?.title}
        category={data?.data?.category}
        img={`${BASEURL}img/${data?.data?.NameFile}`}
      />
      {/* <Plan /> */}
      <Projects />
      <Pictures />
    </div>
  );
};

export default Home;
