import { createContext, useState } from "react";


const PictureContext = createContext();

export function PicCont({ children }) {
  const [showPictures, setShowPictures] = useState(false);
  const [showedPic, setShowedPic] = useState(0);

  const [showUserLogin, setShowUserLogin] = useState(true);

  const changeShowUserLoginContext = () => {
      setShowUserLogin(!showUserLogin);
  }

  const showPicturesContext = (val) =>{
    setShowPictures(val)
  }

  const updatePictureContext = (val) => {
    setShowedPic(val);
  }
  return (
    <PictureContext.Provider
      value={{
        showPictures,
        showPicturesContext,
        updatePictureContext,
        showedPic,
        showUserLogin,
        changeShowUserLoginContext
      }}
    >
      {children}
    </PictureContext.Provider>
  );
}

export default PictureContext;
