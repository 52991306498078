import React, { useState } from "react";
import styles from "./Button.module.css";
import { useEffect } from "react";

const Button = (props) => {
  const [hoverstyle, setHoverStyle] = useState(null);

  useEffect(() => {
    setHoverStyle({
      button: {
        background: `${props.backgroundColor ?? "#f8f8f8"}`,
      },
      span: {
        color: `${props.color ?? "#000"}`,
      },
    });
  }, [props]);

  const onMouseEnterHandler = () => {
    setHoverStyle((prev) => ({
      ...prev,
      button: {
        background: `${props.hoverBackgroundColor ?? "#000"}`,
      },
      span: {
        color: `${props.hoverColor ?? "#f8f8f8"}`,
      },
    }));
  };

  const onMouseLeaveHandler = () => {
    setHoverStyle((prev) => ({
      ...prev,
      button: {
        background: `${props.backgroundColor ?? "#f8f8f8"}`,
      },
      span: {
        color: `${props.color ?? "#000"}`,
      },
    }));
  };

  return (
    <button className={styles.button} style={hoverstyle?.button} onClick={props.onClick} name={props.name} onMouseEnter={onMouseEnterHandler} onMouseLeave={onMouseLeaveHandler}>
      <span style={hoverstyle?.span} name={props.name}>{props.children}</span>
    </button>
  );
};

export default Button;
