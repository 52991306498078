import React, {useEffect, useState, useContext } from "react";
import modules from "../components/Edit.module.css";
import styles from "./Header.module.css";
import DecoratedTitle from "../components/DecoratedTitle";
import UserContext from "../data/UserContext";
import axios from "axios";
import Back from "../icons/back.svg";
import Tick from "../icons/tick.svg";

const Header = props => {
  const {
    BASEURL,
    changeUpload,
    errorHandler,
    newStuff,
    changeStuffContext
  } = useContext(UserContext);

  const [newProject, setNewProject] = useState({
    title: "",
  });

  const [pictures, setPictures] = useState([])

  useEffect(() => {
    setNewProject(prev => ({
      ...prev,
      title: props.title,
    }))
  }, [])


  useEffect(() => {
    if(newStuff === 1 && props.category === "Makettek"){
      const formData = new FormData();
      formData.append("title", props.title);

      axios.post(`${BASEURL}chooseablePictures.php`, formData)
        .then(data => {
          // console.log(data.data.data);
          setPictures(data.data.data)
        });
    }
  }, [newStuff])

  
  const MediaHandler = e => {
    if (e.target.files[0].size <= 25000000) {
      setNewProject(prev => ({
        ...prev,
        media: e.target.files[0],
        mediaValid: false
      }));
    } else {
      errorHandler(
        "A feltölteni kívánt fájl mérete nem lehet nagyobb 25 MB-nál!",
        "fail"
      );
      setNewProject(prev => ({
        ...prev,
        media: e.target.files[0],
        mediaValid: true
      }));
    }
  };


  const NewProject = e => {
    setNewProject(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const sendHandler = () => {
    const url = `${BASEURL}editHeader.php`;
    const formData = new FormData();
    formData.append("title", newProject.title);
    formData.append("category", newProject.category);
    formData.append("file", newProject.media);

    axios.post(url, formData).then(response => {
      if (response.data.error === "none") {
        errorHandler("Új makett létrehozva!", "success");
        changeUpload();
        changeStuffContext(0);
      } else if (response.data.error === "fileSize") {
        errorHandler(
          "A feltölteni kívánt fájl mérete nem lehet nagyobb 25 MB-nál!",
          "fail"
        );
        setNewProject(prev => ({
          ...prev,
          mediaValid: true
        }));
      } else if (response.data.error === "notAllowed") {
        errorHandler("Nem megengedett fájltípus!", "fail");
        setNewProject(prev => ({
          ...prev,
          mediaValid: true
        }));
      } else if (response.data.error === "stmtFail") {
        errorHandler(
          "Hoppá! Valamilyen hiba történt. Próbáld meg újra vagy próbálkozz később.",
          "fail"
        );
      }
    });
  
  };

  const setImgHandler = (val) =>{
    const url = `${BASEURL}setImg.php`;
    const formData = new FormData();
    formData.append("img", val);
    formData.append("title", props.title);

    axios.post(url, formData).then(response => {
      if (response.data.error === "none") {
        errorHandler("Új makett létrehozva!", "success");
        changeUpload();
        changeStuffContext(0);
      } else if (response.data.error === "stmtFail") {
        errorHandler(
          "Hoppá! Valamilyen hiba történt. Próbáld meg újra vagy próbálkozz később.",
          "fail"
        );
      }
    });
  }

  return (
    <header className={styles.header}>
      {newStuff === 1 ? props.category !== "Makettek"
        ? <div className={styles.upload}>
             <div>
          <div className={modules.controlBtns}>
              <button
                className={modules.controls}
                onClick={() => changeStuffContext(0)}
              >
                <img src={Back} />
              </button>
              <button className={modules.controls} onClick={sendHandler}>
                <img src={Tick} />
              </button>
            </div>


             <input
             className={`${newProject.mediaValid ? styles.invalid : styles.input}`}
             type="text"
             name="title"
             placeholder="Cím"
             value={newProject.title}
             onChange={NewProject}
             />
             <input
             className={`${newProject.mediaValid ? styles.invalid : styles.input}`}
             type="title"
             name="category"
             placeholder="Alcím"
             value={newProject.category}
             onChange={NewProject}
             />
          <input
          className={`${newProject.mediaValid ? styles.invalid : styles.input}`}
                type="file"
                name="media"
                accept=".jpg, .jpeg, .png, .svg"
                onChange={MediaHandler}
                capture
                />
                </div>
              
        </div> : <div>
        <div className={styles.imgSelection}>
          {
            pictures.map((pic, index) => 
            !pic.img.includes("iframe") && !pic.img.includes("mp4") ?
            <div className={styles.selectImg} onClick={() => setImgHandler(pic.ImageID)} key={index}><img src={`${BASEURL}img/${pic.img}`} /></div> : ""
            )
          }

</div>
        </div>
        : <>
            <div className={styles.titlesGroup}>
              <DecoratedTitle
                className={styles.title}
                backTitle={styles.BackTitle}
              >
                {props.title}
              </DecoratedTitle>
              <h2 className={styles.category}>
                {props.category}
              </h2>
            </div>
            <div className={styles.titleImg}>
              <img src={props.img} />
            </div>
          </>}
    </header>
  );
};

export default Header;
