import React, { useContext, useEffect, useState } from "react";
import styles from "./SimpleImgHolder.module.css";
import UserContext from "../data/UserContext";

const SimpleImgHolder = (props) => {

    const {BASEURL} = useContext(UserContext);
    const [displayed, setDisplayed] = useState(false);

  
  return (
    <div className={styles.imgBox} onClick={props.onClick}>
    {
      props.img.includes(".mp4") ?
      <video autoPlay="true" muted loop>
        <source src={`${BASEURL}img/${props.img}`} />
      </video>
      :
    <img src={`${BASEURL}img/${props.img}`} />
  }
  </div>
  )
}

export default SimpleImgHolder