import React from "react";
import module from "./DecoratedTitle.module.css";

const DecoratedTitle = (props) => {
  let shadow = props.shadow ?? true;

  const dynamicStyle = {
    FrontTitle: {
      color: props.titleColor ?? "#272a2b",
    },
  };

  return (
    <div className={module.wrapper}>
      <h1 className={`${module.color} ${props.className}`} style={dynamicStyle.FrontTitle}>
        {props.children}
      </h1>
      {shadow ? <h1 className={`${props.backTitle}`}>{props.children}</h1> : ""}
    </div>
  );
};

export default DecoratedTitle;
