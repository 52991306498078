import React, { useEffect, useState, useContext } from "react";
import DecoratedTitle from "../components/DecoratedTitle";
import styles from "./Card.module.css";
import NavLink from "./NavLink";
import UserContext from "../data/UserContext";

const Card = props => {
  const { BASEURL } = useContext(UserContext);
  
  return (
      <div className={styles.card} onClick={props.onClick}>
        <div className={styles.iconHolder}>
          <img src={`${BASEURL}img/${props.icon}`} />
        </div>
        <div className={styles.sepLine} />
        <div className={styles.titleBox}>
          <DecoratedTitle
            className={`${styles.title} ${styles.fontSize}`}
            backTitle={`${styles.BackTitle} ${styles.fontSize}`}
          >
            {props.title}
          </DecoratedTitle>
        </div>
      </div>
  );
};

export default Card;
