import React from "react";

const Menu = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 9H23M1 14H14" stroke="black" stroke-linecap="round" />
    </svg>
  );
};

export default Menu;
