import React, { useRef, useEffect, useState } from "react";
import styles from "./ImgHolder.module.css";
import getData from "./../api/head";
import { useContext } from 'react';
import UserContext from "../data/UserContext";
import modules from "./Post.module.css";
import Trash from "../icons/trash.svg";
import axios from 'axios';

const ImgHolder = props => {
  const {BASEURL, newStuff, errorHandler, changeUpload, upload} = useContext(UserContext);
  let refContainer = useRef(null);
  const [displayed, setDisplayed] = useState(false);
  const [media, setMedia] = useState(null);

  let windowSizeX = window.innerWidth;
  useEffect(
    () => {
      if(props.dynamicLoad === true){

        let elementPosX = refContainer?.current?.getBoundingClientRect().x;
        
      if (elementPosX < windowSizeX * 1.5 && elementPosX > 0) {
        setDisplayed(true);
      }
    } else {
        setMedia(props.img);
    }
    },
    [props.imgBoxScrollPos, upload]
  );

  useEffect(
    () => {
      if(props.dynamicLoad === true){
      if (displayed && props.elementNumber !== null) {
        fetch(`${BASEURL}picture.php?id=${props.elementNumber}`, {
          cache: 'no-store'
        })
          .then(data => data.json())
          .then(data => {
            setMedia(data.media)
          });
      }
    }
    },
    [displayed, upload]
  );

  const deleteHandler = (id) =>{
    const formData = new FormData();
    formData.append("id", id );

    axios.post(`${BASEURL}deleteImg.php`, formData)
    .then((response) => {
      if (response.data.error === "stmtFail") {
        errorHandler(
          "Hoppá! Valamilyen hiba történt. Próbáld meg újra vagy próbálkozz később.",
          "fail"
        );
      } else {
        errorHandler("Kép törölve!", "success");
        changeUpload();
      }
    })
  }

  return (
    <div className={styles.imgBox}>
      {newStuff === 1 ? <button onClick={() => deleteHandler(props.delId)} className={styles.delBtn}><img src={Trash}/></button> : ""}
      {
        !media?.img?.includes("iframe") ? 
        media?.img?.includes(".mp4") ?
        <video ref={refContainer} autoPlay="true" muted loop onClick={props.onClick}>
          <source src={`${BASEURL}img/${media?.img}`} />
        </video>
        :
      <img onClick={props.onClick} ref={refContainer} src={`${BASEURL}img/${media?.img}`} /> : ""
    }
    </div>
  );
};

export default ImgHolder;
