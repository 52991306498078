import React from "react";
import styles from "./Post.module.css";

const PostIcon = (props) => {
  return (
    <div className={styles.icon}>
      <div className={styles.iconHolder}><img src={props.icon} /></div>
      <span>{props.value}</span>
    </div>
  );
};

export default PostIcon;
