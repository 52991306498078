import React from "react";
import styles from "./Login.module.css";
import Input from "./../components/Input";
import Person from "./../icons/Person";
import Key from "./../icons/Key";
import Mail from "./../icons/Mail";
import Button from "./../components/Button";
import { useState, useContext } from "react";
import UserContext from "../data/UserContext";

const Login = () => {
  const {BASEURL, errorHandler, changeShowUserLoginContext} = useContext(UserContext);
  const [form, setForm] = useState(0);
  const [data, setData] = useState({
    username: "",
    email: "",
    password: "",
    passwordAgain: "",
  });
  const [valid, setValid] = useState({
    username: false,
    email: false,
    password: false,
    passwordAgain: false,
  });

  const FormSending = () => {
    fetch(`${BASEURL}user.php`, {
      method: "POST",
      body: JSON.stringify({
        username: data.username,
        email: data.email,
        password: data.password,
        passwordAgain: data.passwordAgain,
      })
    })
    .then((data) => data.json())
    .then((data) => {
      if(data.error === "invalidUsername"){
        errorHandler("A felhasználónév csak betűket és számokat tartalmazhat!", "fail");
        setValid((prev) => ({
          ...prev,
          username: true,
        }));

      } else if(data.error === "invalidEmail"){
        errorHandler("Az email nem megfelelő!", "fail");
        setValid((prev) => ({
          ...prev,
          email: true,
        }));


      } else if(data.error === "passwordsDontMatch"){
        errorHandler("A jelszavak nem egyeznek!", "fail");
        setValid((prev) => ({
          ...prev,
          password: true,
          passwordAgain: true
        }));


      } else if(data.error === "userExists"){
        errorHandler("Ilyen felhasználó már létezik!", "fail");
        setValid((prev) => ({
          ...prev,
          username: true,
          email: true
        }));


      } else if(data.error === "stmtFail"){
        errorHandler("Hoppá! Valamilyen hiba történt. Próbáld meg újra vagy próbálkozz később.", "fail");
      
      } else if(data.error === "wrongPassword"){
        errorHandler("Hibás felhasználónév vagy jelszó!", "fail");
        setValid((prev) => ({
          ...prev,
          username: true,
          password: true
        }));
      } 

      else if(data.error === "logined"){
        errorHandler("Sikeres bejelentkezés!", "success");
        console.log(data)
        sessionStorage.setItem("whoami", data.data);
        changeShowUserLoginContext();
      }
    })
  }

  const FormChanger = (val) => {
    if(form === val){

      if(form < 1){
        if(!valid.username && !valid.password){
          FormSending();
        } else {
          errorHandler("Kérlek tölts ki minden mezőt!", "fail")
        }
      } else{
        if(!valid.username && !valid.email && !valid.password && !valid.passwordAgain){
          FormSending();
        } else {
          errorHandler("Kérlek tölts ki minden mezőt!", "fail")
        }
      }
    }

    setForm(val);
  };

  const formChangeHandler = (e) => {
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));

    validHandler(e);
    // console.log(data);
  };

  const validHandler = (e) => {
    if (e.target.name === "username" && e.target.value.length >= 5) {
      setValid((prev) => ({
        ...prev,
        [e.target.name]: false,
      }));
    } else if (e.target.name === "password" && e.target.value.length >= 8) {
      setValid((prev) => ({
        ...prev,
        [e.target.name]: false,
      }));

      if(e.target.value === data.passwordAgain){
        setValid((prev) => ({
          ...prev,
          passwordAgain: false,
        }));
      } else{
        setValid((prev) => ({
          ...prev,
          passwordAgain: true,
        }));
      }

    } else if (
      e.target.name === "passwordAgain" &&
      e.target.value.length >= 8 &&
      e.target.value === data.password
    ) {
      setValid((prev) => ({
        ...prev,
        [e.target.name]: false,
      }));
    } else if (e.target.name === "email" && e.target.value.includes("@")) {
      setValid((prev) => ({
        ...prev,
        [e.target.name]: false,
      }));
    } else {
      setValid((prev) => ({
        ...prev,
        [e.target.name]: true,
      }));
    }
  };
  return (
    <div className={styles.container}>
      {form < 1 ? (
        <>
          <Input
            icon={<Person />}
            type="text"
            name="username"
            placeholder="Felhasználónév vagy email"
            onChange={formChangeHandler}
            value={data.username}
            valid={valid.username}
          />
          <Input
            icon={<Key />}
            name="password"
            type="password"
            placeholder="Jelszó"
            onChange={formChangeHandler}
            value={data.password}
            valid={valid.password}
          />
        </>
      ) : (
        <>
          <Input
            icon={<Person />}
            type="text"
            name="username"
            placeholder="Felhasználónév"
            onChange={formChangeHandler}
            value={data.username}
            valid={valid.username}
          />
          <Input
            icon={<Mail />}
            type="text"
            name="email"
            placeholder="Email"
            onChange={formChangeHandler}
            value={data.email}
            valid={valid.email}
          />
          <Input
            icon={<Key />}
            type="password"
            name="password"
            placeholder="Jelszó"
            onChange={formChangeHandler}
            value={data.password}
            valid={valid.password}
          />
          <Input
            icon={<Key />}
            type="password"
            name="passwordAgain"
            placeholder="Jelszó újból"
            onChange={formChangeHandler}
            value={data.passwordAgain}
            valid={valid.passwordAgain}
          />
        </>
      )}

      <div className={styles.controls}>
        {/* <Button
          onClick={() => {FormChanger(1)}}
          backgroundColor={form < 1 ? null : "#000"}
          color={form < 1 ? null : "#fff"}
          hoverBackgroundColor={form < 1 ? null : "#bbb"}
          hoverColor={form < 1 ? null : "#000"}
        >
          {form < 1 ? "Új profil" : "Létrehozás"}
        </Button> */}
        <Button
          onClick={() => FormChanger(0)}
          backgroundColor={form < 1 ? "#000" : null}
          color={form < 1 ? "#fff" : null}
          hoverBackgroundColor={form < 1 ? "#bbb" : null}
          hoverColor={form < 1 ? "#000" : null}
        >
          {form < 1 ? "Bejelentkezés" : "Bejelentkezek"}
        </Button>
      </div>
    </div>
  );
};

export default Login;
