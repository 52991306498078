import React, { useState, useContext, useEffect } from "react";
import styles from "./Edit.module.css";
// import modules from "./Post.module.css";
import UserContext from "../data/UserContext";
import axios from "axios";
import Calendar from "../icons/Calendar.svg";
import PostIcon from "./PostIcon";
import Back from "../icons/back.svg";
import Tick from "../icons/tick.svg";

const NewPost = () => {
  const {
    BASEURL,
    changeUpload,
    errorHandler,
    newStuff,
    changeStuffContext,
    showMakett
  } = useContext(UserContext);
  const [newProject, setNewProject] = useState({
    description: "",
    media: "",
    mediaValid: false
  });

  const MediaHandler = e => {
    console.log(e.target.files);
    console.log(URL.createObjectURL(e.target.files[0]));
    for (let i = 0; i < e.target.files.length; i++) {
      if (e.target.files[i].size < 25000000) {
        setNewProject(prev => ({
          ...prev,
          media: e.target.files,
          mediaValid: false
        }));
      } else {
        errorHandler(
          "A feltölteni kívánt fájl mérete nem lehet nagyobb 25MB-nál!",
          "fail"
        );
        setNewProject(prev => ({
          ...prev,
          media: e.target.files,
          mediaValid: true
        }));
      }
    }
  };

  const NewProject = e => {
    setNewProject(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const sendHandler = () => {
    const url = `${BASEURL}newPost.php`;
    const formData = new FormData();
    formData.append("id", showMakett.value);
    formData.append("description", newProject.description);

    for (let i = 0; i < newProject.media.length; i++) {
      formData.append("file[]", newProject.media[i]);
    }

    axios.post(url, formData).then(response => {
      if (response.data.error === "none") {
        errorHandler("Új makett létrehozva!", "success");
        changeUpload();
      } else if (response.data.error === "fileSize") {
        errorHandler(
          "A feltölteni kívánt fájl mérete nem lehet nagyobb 25 MB-nál!",
          "fail"
        );
        setNewProject(prev => ({
          ...prev,
          mediaValid: true
        }));
      } else if (response.data.error === "notAllowed") {
        errorHandler("Nem megengedett fájltípus!", "fail");
        setNewProject(prev => ({
          ...prev,
          mediaValid: true
        }));
      } else if (response.data.error === "stmtFail") {
        errorHandler(
          "Hoppá! Valamilyen hiba történt. Próbáld meg újra vagy próbálkozz később.",
          "fail"
        );
      }

    });
  };

  return (
    <div>
      <div className={styles.controlBtns}>
        <button
          className={styles.controls}
          onClick={() => changeStuffContext(0)}
        >
          <img src={Back} />
        </button>
        <button className={styles.controls} onClick={sendHandler}>
          <img src={Tick} />
        </button>
      </div>
      <div>
        <input
          className={`${newProject.mediaValid ? styles.invalid : styles.input}`}
          type="file"
          name="media"
          accept=".jpg, .jpeg, .png, .svg, .mp4"
          onChange={MediaHandler}
          multiple
          capture
        />
      </div>

      <div>
        <textarea
          name="description"
          onChange={NewProject}
          value={newProject.description}
        />
      </div>
    </div>
  );
};

export default NewPost;
