import styles from "./Makett.module.css";
import Post from "./../components/Post";
import getData from "./../api/head";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import UserContext from "../data/UserContext";
import Header from "./../sections/Header";
import axios from 'axios';

const Makett = props => {
  const { BASEURL, upload } = useContext(UserContext);
  const [data, setData] = useState("");

  useEffect(() => {
    axios.get(`${BASEURL}posts.php?id=${props.project}`).then(resp => {
      // console.log(resp.data);
      setData(resp.data);
    })
    
  }, [upload]);

  return (
    <div>
      {data !== ""
        ? <Header
            title={data.head.title}
            category="Makettek"
            img={`${BASEURL}img/${data.head.img}`}
          />
        : ""}

      <div className={styles.postsContainer}>

        {data !== ""
          ? data.posts.map((post, index) =>
              <Post
                key={index}
                idKey={post.mainPostID}
                media={post.medias}
                description={post.descript}
                calendar={post.calender}
              />
            )
          : ""}
      </div>
    </div>
  );
};

export default Makett;
