import styles from "./Post.module.css";
import PostIcon from "./PostIcon";
import ClickableImg from "./ClickableImg";
import HeartLined from "../icons/HeartLined.svg";
import Calendar from "../icons/Calendar.svg";
import { useContext } from "react";
import UserContext from "../data/UserContext";
import Trash from "../icons/trash.svg";
import axios from 'axios';

const Post = props => {
  const { BASEURL, newStuff, errorHandler, changeUpload } = useContext(UserContext);

  const deleteHandler = () =>{
    const formData = new FormData();
    formData.append("id", props.idKey );

    axios.post(`${BASEURL}deletePost.php`, formData)
    .then((response) => {
      if (response.data.error === "stmtFail") {
        errorHandler(
          "Hoppá! Valamilyen hiba történt. Próbáld meg újra vagy próbálkozz később.",
          "fail"
        );
      } else {
        errorHandler("Poszt törölve!", "success");
        changeUpload();
      }
    })
  }
  return (
    <div className={styles.post}>
      {newStuff === 1 ? <button onClick={deleteHandler} className={styles.delBtn}><img src={Trash}/></button> : ""}
      <div className={styles.postPictures}>
        {props.media !== null
          ? props.media.map((media, index) =>
              media.includes('iframe') ?
              <div
                className={styles.embed}
                dangerouslySetInnerHTML={{ __html: media }}
              />
              :
              <ClickableImg src={`${BASEURL}img/${media}`} kex={index} />
            )
          : ""}
      </div>
      <div className={styles.description}>
        <p>
          {props.description}
        </p>
        <div className={styles.controls}>
          {/* <PostIcon icon={HeartLined} value="320"/> */}
          <PostIcon icon={Calendar} value={props.calendar} />
        </div>
      </div>
    </div>
  );
};

export default Post;
