import React from "react";

const ArrowForward = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 3L17.0918 11.0918C17.4823 11.4823 17.4823 12.1155 17.0918 12.506L8.79883 20.799"
        stroke-linecap="round"
        strokeWidth="3"
      />
    </svg>
  );
};

export default ArrowForward;
